var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"items":_vm.items}}),_c('validation-observer',{ref:"simpleRules"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mb-2"},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":_vm.$t('Case Name'),"tooltipMessage":_vm.$t('Name'),"required":true},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label form-label"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Status"))+" ")]),_c('multiselect',{staticStyle:{"z-index":"9999"},attrs:{"options":['active', 'flagged', 'finished', 'trash'],"multiple":false},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"name":"Case Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label form-label"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Status"))+" ")]),_c('multiselect',{staticStyle:{"z-index":"9999"},attrs:{"options":[
                    'queued',
                    'in-progress',
                    'failed',
                    'unsupported' ],"multiple":false},model:{value:(_vm.form.caseStatus),callback:function ($$v) {_vm.$set(_vm.form, "caseStatus", $$v)},expression:"form.caseStatus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"input-label form-label"},[_vm._v(" "+_vm._s(_vm.$t("Comments"))+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.details),expression:"form.details"}],staticClass:"form-control",domProps:{"value":(_vm.form.details)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "details", $event.target.value)}}})])])])]),_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{staticClass:"d-flex align-items-center gap-1",attrs:{"variant":"primary"},on:{"click":_vm.updateCase}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"12"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Update Case")))])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }