<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mb-2">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <TextInput
                  v-model="form.name"
                  :label="$t('Case Name')"
                  :tooltipMessage="$t('Name')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{ $t("Status") }}
                </label>
                <multiselect
                  style="z-index: 9999"
                  v-model="form.status"
                  :options="['active', 'flagged', 'finished', 'trash']"
                  :multiple="false"
                >
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <validation-provider
                  #default="{ errors }"
                  name="Case Status"
                  rules="required"
                >
                  <label class="input-label form-label"
                    ><span style="color: red">*</span>&nbsp;{{ $t("Status") }}
                  </label>
                  <multiselect
                    style="z-index: 9999"
                    v-model="form.caseStatus"
                    :options="[
                      'queued',
                      'in-progress',
                      'failed',
                      'unsupported',
                    ]"
                    :multiple="false"
                  >
                  </multiselect>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </div>
            <div class="col-md-12">
              <label class="input-label form-label">
                {{ $t("Comments") }}
              </label>
              <textarea class="form-control" v-model="form.details"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="primary"
          @click="updateCase"
          class="d-flex align-items-center gap-1"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Update Case") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import Multiselect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-quill-editor";
import { required, email } from "@validations";

export default {
  components: {
    TextInput,
    PageHeader,
    Multiselect,
    ValidationProvider,
    ValidationObserver,
    VueEditor,
  },
  computed: {
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("case-list"),
          to: "/my-cases",
        },
        {
          text: this.$t("Edit"),
          active: true,
        },
      ];
    },
    ...mapGetters("caseLists"),
  },
  data() {
    return {
      form: {
        name: "",
        details: "",
        status: "",
        caseStatus: "",
      },
    };
  },
  async mounted() {
    await this.$store
      .dispatch("caseLists/show", this.$route.params.id)
      .then(async (res) => {
        var response = res?.data?.data;
        this.form.name = response?.name ?? "";
        this.form.details = response?.details ?? "";
        this.form.status = response?.status ?? null;
        this.form.caseStatus = response?.caseStatus ?? "";
      });
  },
  methods: {
    async updateCase() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          await this.$store.dispatch("caseLists/update", {
            id: this.$route.params.id,
            data: {
              ...this.form,
            },
          });
          this.$router.push("/my-cases");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}

.news-image {
  margin-top: 10px;
  img {
    width: 200px;
    object-fit: contain;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 5px;
    border-radius: 5px;
  }
}
</style>
